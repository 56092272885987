// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBs1iI5plV-ckcw4AmJt5av4M4mzDerEks",
    authDomain: "covetech-site.firebaseapp.com",
    projectId: "covetech-site",
    storageBucket: "covetech-site.appspot.com",
    messagingSenderId: "29747504190",
    appId: "1:29747504190:web:b3e7061a4fb73ad139dd6f",
    measurementId: "G-6GG9YLDTMW"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


export async function sendMessage(name, email, message) {
    try {
        const docRef = await addDoc(collection(db, "messages"), {
            name: name,
            email: email,
            message: message,
            createdAt: (new Date()).getTime()
        });
        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}
