import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider, responsiveFontSizes} from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    textAlign: 'left',
                    fontFamily: 'Open Sans',
                },
                h3: {
                    fontWeight: 600,
                    fontSize: "3.25rem"
                },
                h4:{
                    fontWeight: 600,
                    letterSpacing: 0,
                    fontSize: "2.2rem",
                },
                h5: {
                    fontWeight: 600,
                    fontSize: "1.5rem",
                },
                h6: {
                    color: 'lightgray',
                    fontSize: "1.25rem",
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '0px',
                }
            }
        }
    },
    palette: {
        info: {
            main: '#93c5fd',
        }
    }
});


root.render(
    <>
        <React.StrictMode>

            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com"/>
            <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet"/>
            <ThemeProvider theme={responsiveFontSizes(theme, {
                breakpoints: ['xs', 'md', 'xl'],
                factor: 2
            })}>
                <App/>
            </ThemeProvider>
        </React.StrictMode></>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
