import {
    Box,
    Button,
    Divider,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    Snackbar,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Link from '@mui/material/Link';
import React, {useState} from 'react';
import './App.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import Reviews from './Reviews';
import MenuIcon from '@mui/icons-material/Menu';
import {sendMessage} from "./firebase";

const ServiceBox = ({title, description, image}: { title: string, description: string, image: string }) => {
    const medium = useMediaQuery('(min-width:1000px)');
    const small = useMediaQuery('(max-width:750px)');
    const height = !medium && !small ? 194 : 240;
    const width = !medium && !small ? 256 : 320;
    return <Stack alignItems={"flex-start"} justifyContent={"center"} width={width}>
        <Box borderBottom={"4px solid blue"}
             sx={{objectFit: "cover", background: `url(${image})`, backgroundSize: "cover"}}
             height={height} width={width}/>
        <Typography variant={"h5"}>{title}</Typography>
        <Typography>{description}</Typography>
    </Stack>
}
const Dropdown = () => {
    const [open, setOpen] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return <><Drawer
        anchor={"top"}
        variant={"temporary"}
        id="basic-menu"
        open={open}
        onClose={handleClose}
        transitionDuration={500}
    >

        <List sx={{paddingRight: "10px"}}>
            <ListItem onClick={handleClose}><Link href={"#Services"} sx={{
                textDecoration: "none",
                color: "black"
            }}>Services</Link></ListItem>
            <ListItem onClick={handleClose}><Link href={"#Scheduling"} sx={{
                textDecoration: "none",
                color: "black"
            }}>Scheduling</Link></ListItem>
            <ListItem onClick={handleClose}><Link href={"#Reviews"} sx={{textDecoration: "none", color: "black"}}>Customer
                Reviews</Link></ListItem>
            <ListItem onClick={handleClose}><Button href={"#Contact"} variant={"contained"}>Contact
                Us</Button></ListItem>
        </List>
    </Drawer>

        <IconButton sx={{float: "right"}} onClick={handleClick}>
            <MenuIcon/>
        </IconButton>
    </>
}

function App() {

    const large = useMediaQuery('(min-width:750px)');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const Header = () => {
        return <Stack direction={"row"} justifyContent={"space-between"} padding={"10px"} alignItems={"center"}
                      width={"90%"}>
            <Link href={"/"} sx={{textDecoration: "none", color: "black"}}><Typography
                variant={"h5"}>CoveTech</Typography></Link>
            <Stack direction={"row"} spacing={3} alignItems={"center"}><Link href={"#Services"} sx={{
                textDecoration: "none",
                color: "black"
            }}>Services</Link>
                <Link href={"#Scheduling"} sx={{textDecoration: "none", color: "black"}}>Scheduling</Link>
                <Link href={"#Reviews"} sx={{textDecoration: "none", color: "black"}}>Customer
                    Reviews</Link>
                <Button href={"#Contact"} variant={"contained"}>Contact Us</Button></Stack>
        </Stack>
    }
    return (
        <Stack alignItems={"center"} className="App" spacing={5}>
            <Stack width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                {large ? <Header/> :
                    <Stack direction={"row"} justifyContent={"space-between"} padding={"10px"} alignItems={"center"}
                           width={"90%"} position={"absolute"} top={0} left={0} right={0}>
                        <Typography
                            variant={"h5"}>CoveTech</Typography>
                        <Dropdown/>
                    </Stack>
                }
                <Divider sx={{width: "100%"}}/>
            </Stack>

            <Grid container direction={"row"} width={"100%"} alignItems={"center"} spacing={3} paddingRight={5}>
                <Grid item xs={12} md={3} lg={5}><Box sx={{
                    display: "flex",
                    clipPath: "circle()",
                    alignItems: "center",
                    justifyContent: "center",
                    maxHeight: 560,
                    height: large ? "100%" : 300,
                    width: "100%",
                    overflow: "hidden",
                }}>
                    <img src={"/images/title_img.png"} alt={"title"}/>
                </Box></Grid>
                <Grid md={9} lg={7} item><Stack alignItems={"flex-start"} justifyContent={"flex-start"} spacing={3}>
                    <Typography variant={"h3"} textAlign={"left"}>Expert Software Engineering and AI
                        Services</Typography>
                    <Typography variant={"h6"} sx={{color: "#334155"}} textAlign={"left"}>
                        We specialize in machine learning,
                        cloud architecture,
                        Internet of Things devices,
                        and full stack development.
                        With our expertise and cutting-edge solutions,
                        we help businesses thrive in the digital age.
                    </Typography>
                    <Button href={"#Contact"} variant={"contained"}>Contact Us</Button>
                </Stack>
                </Grid>
            </Grid>

            <Stack sx={{backgroundColor: "#f1f5f9"}} width={"100%"} height={"fit-content"} paddingY={"75px"} spacing={3}
                   id={"Services"}>
                <Typography variant={"h4"} textAlign={"center"}>Our Services</Typography>
                <Stack direction={large ? "row" : "column"} justifyContent={"space-evenly"} width={"100%"}
                       alignItems={large ? "flex-start" : "center"} spacing={5}>
                    <ServiceBox title={"Cloud Architecture"}
                                description={"We specialize in designing and implementing scalable and secure cloud architectures to help businesses leverage the power of the cloud."}
                                image={"/images/cloud.png"}/>
                    <ServiceBox title={"Machine Learning Solutions"}
                                description={"Our machine learning solutions help businesses analyze large" +
                                    " datasets, uncover patterns, and make data-driven decisions for improved efficiency and" +
                                    " productivity."} image={"/images/machinelearning.png"}/>
                    <ServiceBox title={"Full Stack Development"} image={"/images/wire.png"}
                                description={"We seamlessly create custom apps/websites, marrying" +
                                    " cutting-edge design with robust functionality to meet your unique tech needs."}/>
                </Stack>

            </Stack>
            <Stack paddingY={"50px"} id={"Scheduling"}>
                <Typography variant={"h4"} textAlign={"center"}>Unlock the power of technology</Typography>
                <Typography textAlign={"center"}>Transform your business with our expertise!
                </Typography>
            </Stack>
            <Stack sx={{backgroundColor: "#f1f5f9"}} width={"100%"} height={"fit-content"}>
                <Box
                    sx={{
                        overflow: "hidden",
                        backgroundPosition: "center",
                        background: "url(/images/keyboard.png)",
                        backgroundSize: "cover"
                    }}
                    maxHeight={640} minHeight={160} height={320} width={"100%"}/>
                <Stack sx={{height: "fit-content", paddingY: "100px", background: "#020617"}} display={"flex"}
                       alignItems={"center"}>
                    <Stack alignItems={"center"} width={"70%"} spacing={3}>
                        <Typography variant={"h4"} textAlign={"center"} color={"white"}>
                            Ready to take your technology projects to the next level?
                        </Typography>
                        <Typography variant={"h6"} textAlign={"center"}>Schedule a time to connect with
                            our experts.</Typography>
                        <Button href={"https://calendly.com/covetech-consulting/covetech-intro"} variant={"contained"}
                                color={"info"}>Schedule Now</Button>
                    </Stack>

                </Stack>

                <Stack spacing={2} padding={"50px"} sx={{backgroundColor: "#fff"}} id={"Reviews"}>
                    <Typography variant={"h4"} textAlign={"center"}>Customer Reviews</Typography>
                    <Reviews/>
                </Stack>
                <Stack sx={{backgroundColor: "#f1f5f9"}} width={"100%"} height={"fit-content"} paddingY={"50px"}
                       id={"Contact"} spacing={5}>
                    <Typography variant={"h4"} textAlign={"center"}>Contact Us</Typography>
                    <Stack sx={{alignItems: "center", justifyContent: "space-evenly", width: "100%"}}>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            const {name, email, message} = e.target as any;
                            setLoading(true);
                            sendMessage(name.value, email.value, message.value).then(() => {
                                //     @ts-ignore
                                e.target.name.value = "";
                                //     @ts-ignore
                                e.target.email.value = "";
                                //     @ts-ignore
                                e.target.message.value = "";

                                setOpen(true);
                                setLoading(false);
                            });
                        }}
                              style={{display: "flex", flexDirection: "column", width: "50%"}}
                        >
                            <Stack spacing={3}>
                                <TextField label={"Name"} name={"name"} required/>
                                <TextField label={"Email"} name={"email"} required type={"email"}/>
                                <TextField label={"Message"} name={"message"} required minRows={3} multiline/>
                                <LoadingButton variant={"contained"} type={"submit"} loading={loading}
                                               sx={{width: "fit-content", alignSelf: "center"}}>Submit</LoadingButton>
                            </Stack>
                        </form>
                        <Snackbar
                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                            open={open}
                            onClose={() => setOpen(false)}
                            autoHideDuration={3000}
                            message="Thanks for sending us a message! We'll get back to you soon."
                        />
                    </Stack>
                </Stack>
                <Stack sx={{backgroundColor: "#1e293b"}} width={"100%"} height={"fit-content"} paddingY={"50px"}>
                    <Typography variant={"h4"} textAlign={"center"} color={"white"}>CoveTech</Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default App;
