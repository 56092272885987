import Carousel from 'react-material-ui-carousel'
import React, {useState} from "react";
import {Stack, Typography} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIoS from '@mui/icons-material/ArrowForwardIos';

export default function Reviews() {
    const items = [
        {
            name: "Anacove - Hospitality Energy Management",
            text: "Working with CoveTech on our IoT solution. They have provided us with cutting-edge solutions that have significantly improved efficiency and performance. Highly knowledgeable and reliable!"
        },
        {
            name: "WEALTHAWK - SaaS Platform for Financial Advisors",
            text: "CoveTech has been instrumental in our startup. Their team has a deep understanding of software engineering best practices and has helped us bring our ideas to life. Their professionalism and attention to detail are commendable."
        },
        {
            name: "Emulait - Infant Feeding Tech",
            text: "CoveTech's expertise in IoT implementation, hardware design, and efficient management of remote teams has been instrumental in elevating Emulait's technology initiatives."
        },
        {
            name: "PrepMe - Sales Preparation Tool",
            text: "Ethan has a brilliant technical mind that can think strategically and help execute technical solutions with quality and cost. He is quick to trouble shoot issues and come up with solutions that create immediate value."
        },

        {
            name: "Global Sense - Automotive IoT Sensors",
            text: "Their adept team not only improved our existing IoT infrastructure but also redesigned our companion app to be more user-friendly. We are immensely satisfied with CoveTech's innovative solutions, marking them as a trusted ally in advancing our technological capabilities."
        }
    ]
    const [num, setNum] = useState(1);

    return (
        <Stack alignContent={"center"}>
            <Carousel navButtonsAlwaysVisible duration={600} animation={"slide"}
                      NextIcon={<ArrowForwardIoS color={"primary"} fontSize={"large"}/>}
                      PrevIcon={<ArrowBackIosIcon color={"primary"} fontSize={"large"}/>}
                      navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                          style: {
                              backgroundColor: 'unset',
                              borderRadius: 0,
                              color: 'unset',
                              fontSize: 80
                          }
                      }}
                      fullHeightHover={false}
                      navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                          style: {
                              bottom: 0,
                              top: 'unset'
                          }
                      }}
                      sx={{paddingTop: 5, paddingBottom: 10}}
                      onChange={(current, active) => {
                          if (active !== undefined) setNum(active + 1)
                      }}
                      indicators={false}
            >
                {
                    items.map((item, i) => <Item key={i} item={item}/>)
                }
            </Carousel>
            <Typography textAlign={"center"} sx={{marginTop: -7}}>{num} of {items.length}</Typography>
        </Stack>
    );
}


function Item(props: { key: number, item: { name: string, text: string } }) {
    return (
        <Stack width={"100%"} alignItems={"center"} spacing={2}>
            <Typography>{props.item.text}</Typography>
            <Typography textAlign={"center"} color={"primary"} fontWeight={1000}
                        width={"100%"}>{props.item.name}</Typography>
        </Stack>
    )
}
